<template>
  <div class="animated fadeIn">
    <h4 class="mb-3">
      Личные данные
    </h4>
    <b-form @submit.prevent="onSubmit">
      <b-form-group label="ФИО">
        <b-form-input
          v-model.trim="$v.form.fullname.$model"
          type="text"
          :state="chkState('fullname')"
          aria-describedby="accountFormFullname"
        />
        <b-form-invalid-feedback id="accountFormFullname">
          <span v-if="!$v.form.fullname.сyrillic">Поле принимает только символы кириллицы</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Телефоны">
        <multiple-phones-input v-model="form.userPhones" />
      </b-form-group>
      <b-form-group label="Оcновная почта">
        <b-form-input
          v-model.trim="$v.form.email.$model"
          type="email"
          autocomplete="email"
          :state="chkState('email')"
          aria-describedby="accountFormEmail"
        />
        <b-form-invalid-feedback id="accountFormEmail">
          <span v-if="!$v.form.email.email">Поле принимает только email адрес</span>
          <span v-if="!$v.form.email.required">Поле обязательно для заполнения</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Email для уведомлений">
        <b-form-input
          v-model.trim="$v.form.notificationEmail.$model"
          type="email"
          autocomplete="notificationEmail"
          :state="chkState('notificationEmail')"
          aria-describedby="accountFormNotificationEmail"
        />
        <b-form-invalid-feedback id="accountFormNotificationEmail">
          <span v-if="!$v.form.notificationEmail.email">Поле принимает только email адрес</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Skype">
        <b-form-input
          v-model.trim="$v.form.skype.$model"
          type="text"
        />
      </b-form-group>
      <b-form-group label="ICQ">
        <b-form-input
          v-model.trim="$v.form.icq.$model"
          type="text"
        />
      </b-form-group>
      <div slot="footer">
        <b-button
          type="submit"
          variant="primary"
          :disabled="loading"
          :class="{'loading' : loading}"
        >
          Сохранить
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {helpers} from 'vuelidate/lib/validators';
const сyrillic = helpers.regex('alpha', /[а-я]/);
import {required, alphaNum, email, numeric, or} from 'vuelidate/lib/validators';
import notifications from '../../components/mixins/notifications';
import {userUpdate, userRead} from '../../services/api';
import multiplePhonesInput from '../Forms/MultiplePhonesInput';

const formShape = {
  fullname: '',
  email: '',
  notificationEmail: '',
  userPhones: [],
  skype: '',
  icq: '',
};

export default {
  name: 'UserInfo',
  components: {multiplePhonesInput},
  mixins: [validationMixin, notifications],
  data() {
    return {
      form: Object.assign({}, formShape),
      notifications: {
        browser: false,
        email: false,
      },
      feedBack: 'secondary',
      loading: false,
    };
  },
  computed: {
    formStr() {
      return JSON.stringify(this.form, null, 4);
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
  },
  validations: {
    form: {
      fullname: {
        сyrillic,
      },
      email: {
        email,
        required,
      },
      notificationEmail: {
        email,
      },
      userPhones: [],
      skype: '',
      icq: '',
    },
  },
  mounted() {
    this.userRead();
  },
  methods: {
    phoneValidate(e) {
      if (/_/.test(e.$model)) {
        e.$model = '';
      }
    },
    async userRead() {
      this.loading = true;
      const response = await userRead(this.$store.state.user.userId);
      if (response && response.status === 200) {
        for (const [key, value] of Object.entries(this.form)) {
          this.form[key] = response.data[key];
        }
      }
      this.loading = false;
    },
    async onSubmit() {
      if (this.isValid) {
        this.loading = true;
        const response = await userUpdate(this.$store.state.user.userId, this.form);
        if (response && response.status === 200) {
          this.showSuccess('Данные успешно обновлены');
        }
        this.loading = false;
      }
    },
    chkState(val) {
      const field = this.$v.form[val];
      if (!field.$dirty) {
        return null;
      } else if (field.$invalid) {
        return false;
      } else {
        return true;
      }
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some((child) => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

